import Hero from "../../components/Hero";
import TextBlock from "../../components/TextBlock";
import { partnershipGreens } from "../../themes/colours";
import { Container, Image, Tab, Tabs } from "react-bootstrap";
import "./styles.scss";
import { Helmet } from "react-helmet";
const InsightsPage = () => {
  const blocks = [
    {
      imageUrl:
        "/assets/photography/2x2023_BM_RetailMedia_JohnLewis_Shot_9_79_2.jpg",
      title: "Target those most relevant to you",
      text: "By leveraging dunnhumby’s data science capabilities, we can provide a relevant, personalised and superior shopping experience for customers at scale to build greater connections between brands and the customers that matter most to you. Importantly you can also truly measure the impact of your campaigns and optimise the next to make the most of your investment so you never waste a £.",
    },
    {
      imageUrl:
        "/assets/photography/2x2023_BM_RetailMedia_JohnLewis_Shot_1_220.jpg",
      title:
        "Deliver better, faster and different data-based decisions in a challenging and ever changing market",
      text: "We want to work with you to create exceptional customer experiences that stay ahead of customer needs - we will utilise this data to enable you to have the retailer view of supplier categories so we have true transparency.",
    },
    {
      imageUrl:
        "/assets/photography/2x20230831_BM_RetailMedia_Waitrose_3_201.jpg",
      title: "Help drive the change for customers",
      text: "Put customers at the heart of every decision to drive positive change and better category decisions.",
    },
  ];

  return (
    <div className="insights-page">
      <Helmet>
        <title>
          Gain insights for retail media with the John Lewis Partnership
        </title>
        <meta
          name="description"
          content="The John Lewis Partnership brands offer collaboration opportunities with suppliers to gain customer insights through data and access instore retail media advertising."
        />
      </Helmet>
      <Hero
        colour={partnershipGreens.lightGreen}
        title="True connections start with understanding"
      />
      <Container className="w-100 main-container">
        <p className="body-l body-text">
          Plan and execute campaigns with confidence, backed by our wide range
          of data and insight services. Our tools will help you to develop a
          deeper understanding of your trading performance, as well as your
          customers’ behaviour and habits.
        </p>
      </Container>
      <div className="form-background">
        <Container className="w-100 main-container">
          <h3 className="text-md-center">
            Gain a deeper understanding your customers and trading performance
          </h3>
          <Tabs fill>
            <Tab
              title="Understanding your Trading Performance"
              eventKey="trading"
            >
              <div className="text-container">
                <p className="heading-s heading-bold">dunnhumby Assortment</p>
                <p className="body-l">
                  Drive more sales by understanding product performance, using a
                  range of insights and recommendations.
                </p>

                <p className="body-l">Our trio of levers are:</p>

                <ul className="body-l styled-list">
                  <li>
                    Performance – to identify high value, important, popular
                    products
                  </li>
                  <li>
                    Coverage – to maximise appeal by recognising customer needs
                    and ensuring breadth before depth
                  </li>
                  <li>
                    Relevance – to deliver maximum appeal for all customers
                    whilst meeting specific needs and differentiation across
                    stores
                  </li>
                </ul>
                <p className="body-l">
                  Get in touch with alison.bennett@waitrose.co.uk to find out
                  more.
                </p>
              </div>
              <div className="text-container">
                <p className="heading-s heading-bold">
                  John Lewis Returns Dashboard
                </p>
                <p className="body-l mb-4">
                  Our returns dashboard showcases John Lewis returns data with
                  different layers of granularity, including the reason for
                  return. The dashboard will give you insights into which
                  products, and product types, are most frequently returned and
                  their return rate – sharply increasing your trading
                  performance intelligence.
                </p>
                <p className="body-l">
                  Speak to your John Lewis buyer to find out more.
                </p>
              </div>
            </Tab>
            <Tab title="Understand your Customers Better" eventKey="customers">
              <div className="text-container">
                <p className="heading-s heading-bold">dunnhumby Shop</p>
                <p className="body-l">
                  Make better, faster decisions to improve your customers’
                  shopping experience. dunnhumby’s web-based software gives you
                  easy, self-serve access to our John Lewis Partnership customer
                  insights.
                </p>

                <p className="body-l">
                  Understand customer behaviours and trends including:
                </p>
                <ul className="body-l styled-list">
                  <li>Category / brand reporting and benchmarking</li>
                  <li>Performance drivers</li>
                  <li>Deep customer insights</li>
                  <li>Trade marketing activities.</li>
                </ul>
                <p className="body-l">
                  Use the insights to build better category strategies and
                  create joint plans with us, collaborating closely to put the
                  customer at the heart of every decision.
                </p>
                <p className="body-l">
                  For more information on the Insights platform:
                </p>
                <p className="body-l">
                  For Waitrose suppliers, please contact Alison Bennett:
                  Alison.bennett@waitrose.co.uk
                </p>
                <p className="body-l">
                  For John Lewis suppliers, please contact George Brett:
                  George.Brett@johnlewis.co.uk
                </p>
              </div>
            </Tab>
          </Tabs>
        </Container>
      </div>
      <Container className="w-100 main-container">
        <div className="body-text">
          <Image
            className="dunnhumby-logo"
            src="/assets/logos/JLP_Insights_and_Media_Dunnhumby_RGB_Green.svg"
          />
          <p className="body-l">
            The John Lewis Partnership and dunnhumby have partnered together to
            drive actionable insights from 20 million customers who shop across
            the Partnership. By leveraging dunnhumby’s world leading data
            science capabilities we understand customers better than ever before
            and can deliver solutions that enable relevant, personalised
            connections. Ultimately, this delivers deeper customer loyalty and
            drives mutual growth.
          </p>
          <p className="body-l">
            We work with brands and agencies so they can benefit from insights
            generated from more than 9 million members of My Waitrose and 5
            million My John Lewis members across online and instore channels.
            This enriched data gives:
          </p>
          <ul className="styled-list">
            <li>customer-first strategic decision-making insights</li>
            <li>greater visibility to customer behaviour</li>
            <li>
              a deep and rich view of the Category, what’s driving growth, where
              your opportunities are
            </li>
            <li>ability to track your product performance over time</li>
            <li>an understanding of how your NPD launches perform</li>
          </ul>
        </div>
      </Container>
      <Container className="w-100 main-container">
        <TextBlock blocks={blocks} />
      </Container>
    </div>
  );
};

export default InsightsPage;
